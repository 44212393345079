// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "railroad-editor-20190824171919-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://dcenny7deo578.cloudfront.net",
    "aws_cognito_identity_pool_id": "ap-northeast-1:d5874b97-c161-415e-89e3-e6b46a2580b9",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_GUllp0rXg",
    "aws_user_pools_web_client_id": "6sp9ukc4nhin2otj1dm3v2m0lp",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "layout",
            "endpoint": "https://yt681qbok8.execute-api.ap-northeast-1.amazonaws.com/prod",
            "region": "ap-northeast-1"
        },
        {
            "name": "issue",
            "endpoint": "https://swf03irdq0.execute-api.ap-northeast-1.amazonaws.com/prod",
            "region": "ap-northeast-1"
        },
        {
            "name": "session",
            "endpoint": "https://yq21kftji7.execute-api.ap-northeast-1.amazonaws.com/prod",
            "region": "ap-northeast-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "ap-northeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "layouts-prod",
            "region": "ap-northeast-1"
        },
        {
            "tableName": "sessions-prod",
            "region": "ap-northeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "railroad-editor-user-files-prod",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
